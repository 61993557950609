<template>
  <div>
    <p class="mb-3">
      Determine the type of bond depicted in the cartoon illustrations on the left.
    </p>
    <v-form @submit.prevent="submitResponse">
      <table>
        <tr>
          <td style="vertical-align: middle; text-align: center">
            <img src="/img/assignments/p-pi bond.png" width="132" height="120" class="m-3" />
          </td>
          <td>
            <v-radio-group v-model="inputs.input1" class="pl-6">
              <v-radio
                v-for="option in options1"
                :key="option.value"
                class="d-inline-block"
                :value="option.value"
              >
                <template #label>
                  <stemble-latex :content="option.text" />
                </template>
              </v-radio>
            </v-radio-group>
          </td>
        </tr>

        <tr>
          <td style="vertical-align: middle; text-align: center">
            <img src="/img/assignments/p-sigma bond.png" width="200" height="84" class="m-3" />
          </td>
          <td>
            <v-radio-group v-model="inputs.input2" class="pl-6">
              <v-radio
                v-for="option in options1"
                :key="option.value"
                class="d-inline-block"
                :value="option.value"
              >
                <template #label>
                  <stemble-latex :content="option.text" />
                </template>
              </v-radio>
            </v-radio-group>
          </td>
        </tr>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

const HYDROGEN_BOND = 'a';
const SIGMA_BOND = 'b';
const IONIC_BOND = 'c';
const PI_BOND = 'd';
const COVALENT_BOND = 'e';

export default {
  name: 'Question45',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: 'Hydrogen bond', value: HYDROGEN_BOND},
        {text: '$\\sigma$-bond', value: SIGMA_BOND},
        {text: 'Ionic bond', value: IONIC_BOND},
        {text: '$\\pi$-bond', value: PI_BOND},
        {text: 'Covalent bond', value: COVALENT_BOND},
      ],
    };
  },
};
</script>
